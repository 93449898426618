import React from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Input } from 'antd';

import {
    parseIntOr,
    parseBoolOr,
    parseStringOr,
    isArrayNotEmpty
} from '../../App/AppxUtils';

import './index.css';


class InputTextArea extends React.Component {

    constructor(props) {
        super(props);

        this.name = parseStringOr(props.name, "");

        if (this.name === "") {
            throw new Error('Name is required!');
        }

        this.label = parseStringOr(props.label, null);
        this.placeholder = parseStringOr(props.placeholder, null);
        this.disabled = parseBoolOr(props.disabled, false);
        this.required = parseBoolOr(props.required, false);

        if (this.disabled) {
            this.required = false;
        }
        this.rows = parseIntOr(this.props.rows, 12);
        this.setRules();

        this.state = {
            v: ""
        }

        this.onChange = this.onChange.bind(this);
        this.setVal = this.setVal.bind(this);
    }

    setRules() {
        const { t } = this.props;
        let label = this.name;

        if (this.label != null) {
            label = this.label;
        }
        else if (this.placeholder != null) {
            label = this.placeholder;
        }

        this.rules = [{
            required: this.required,
            message: t(`Please input your ${label}!`)
        }];

        if (isArrayNotEmpty(this.props.rules)) {
            for (let i in this.props.rules) {
                let row = this.props.rules[i];

                for (let field in row) {
                    this.rules[i][field] = row[field];
                }
            }
        }
    }

    onChange(e) {
        this.setVal(e.target.value);
    }

    setVal(v) {
        if (undefined !== this.props.onChange) {
            this.props.onChange(this.name, v);
        }

        this.setState({v: v});
    }

    render() {
        return (
            <Form.Item name={this.name} label={this.label} rules={this.rules} style={this.props.style}>
                <Input.TextArea
                    rows={this.rows}
                    disabled={this.disabled}
                    onChange={this.onChange}
                    onPressEnter={e => e.preventDefault()} />
            </Form.Item>
        )
    }
}

export default withTranslation("appxweb")(InputTextArea);