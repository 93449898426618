import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Avatar } from 'antd';

class AppxLangAvatar extends React.Component {

    constructor(props) {
        super(props);

        const { i18n } = this.props;

        this.state = {
            currentLanguage: i18n.language,
            altLngLabel: (i18n.language === 'fr') ? 'En' : 'Fr'
        };

        this.changeLanguage = this.changeLanguage.bind(this);
    }

    componentDidMount() {
        const userLang = localStorage.getItem('userLang');

        if (userLang !== null && userLang !== this.state.currentLanguage) {
            this.changeLanguage();
        }
    }

    async changeLanguage() {
        if (this.state.currentLanguage === 'fr') {
            await this.setState({
                currentLanguage: 'en',
                altLngLabel: 'Fr'
            });
        }
        else {
            await this.setState({
                currentLanguage: 'fr',
                altLngLabel: 'En'
            });
        }

        const { i18n } = this.props;
        i18n.changeLanguage(this.state.currentLanguage);
        localStorage.setItem('userLang', this.state.currentLanguage);
        if ( typeof this.props.onChange == "function" ) { 
            setTimeout(this.props.onChange(this.state.currentLanguage),0);
        }
        
    }

    render() {
        return (
            <Link key="change-language" onClick={this.changeLanguage} to="#">
                <Avatar className="change-language">
                    {this.state.altLngLabel}
                </Avatar>
            </Link>
        )
    }
}

export default withTranslation('appxweb')(AppxLangAvatar);