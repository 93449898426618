import React from 'react';
import { withTranslation } from 'react-i18next';
import { Radio } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
    parseStringOr,
    parseBoolOr,
    isArrayNotEmpty
} from '../../App/AppxUtils';

import './index.css';


class InputRadio extends React.Component {

    constructor(props) {
        super(props);

        this.name = parseStringOr(props.name, "");

        if (this.name === "") {
            throw new Error('Name is required!');
        }

        this.label = parseStringOr(props.label, null);
        this.disabled = parseBoolOr(props.disabled, false);
        this.required = parseBoolOr(props.required, false);
        this.setRules();

        this.state = {
            v: "",
        }

        this.onChange = this.onChange.bind(this);
    }

    setRules() {
        const { t } = this.props;

        this.rules = [{
            required: this.required,
            message: t((this.label != null) ? `Please choose a ${this.label}!` : `Please choose a ${this.name}!`)
        }];

        if (isArrayNotEmpty(this.props.rules)) {
            for (let i in this.props.rules) {
                let row = this.props.rules[i];

                for (let field in row) {
                    this.rules[i][field] = row[field];
                }
            }
        }
    }

    componentDidMount() {
        if (undefined !== this.props.initialValue) {
            this.setVal(this.props.initialValue);
        }
    }

    componentDidUpdate(prevProps) {
        let label = parseStringOr(this.props.label, null);

        if (label !== prevProps.label) {
            this.label = label;
        }
    }

    onChange(e) {
        this.setVal(e.target.value);
    }

    setVal(v) {
        if (undefined !== this.props.onChange) {
            this.props.onChange(this.name, v);
        }

        this.setState({v: v});
    }

    render() {
        const options = this.props.options || [];

        return (
            <Radio.Group name={this.name} onChange={this.onChange} value={this.state.v} className="input-radio-comp">
                {options && options.length > 0 && options.map(item => (
                <Radio key={`comp-radio-${item.value}`} style={this.props.style} value={item.value}>
                    {item.icon && (<FontAwesomeIcon icon={item.icon} size="1x" style={{marginRight: '8px'}} />)}
                    <div className="labelbox">
                        {item.label}
                        {item.label2 && (<span className="label2">{item.label2}</span>)}
                    </div>
                </Radio>
                ))}
            </Radio.Group>
        )
    }
}

export default withTranslation("appxweb")(InputRadio);