import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '../../contexts/AuthContext';
import { PageView } from '../../components/PageView';
import Loading from '../../components/Loading';
import { isObjectNotEmpty, parseIntOr } from '../../components/App/AppxUtils';
import logos from "./cxa-fa-uptv.gif";
import "./index.css";
import trioen from "./trioultimeen.png";
import triofr from "./trioultimefr.png";
import buyfr from "./buynowfr.png";
import buyen from "./buynowen.png";

export default function () { 
    const { t,i18n } = useTranslation('cxa');

    let trio = trioen;
    let buy = buyen;
    console.log("render:" + i18n.language);
    if (i18n.language == "fr" ) {

            trio = triofr;
            buy = buyfr;
    }
    return (
        <div className='welcome-main'>
            <hr />
            <p>
            <img src={logos} className="logos-img"/>
            <br />
            <br />
            {t("index.presentation")}
            </p><br />
            <div className='sep'>
            <hr />
            </div>
            <img src={trio} style={{width:500}}/>
            <br/>
            <a href={t("index.buylink")}>
                <img src={buy} style={{height:60,marginLeft:400}} />
            </a>
        </div>
        );
}