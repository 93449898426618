import React from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Input } from 'antd';

import {
    isArrayNotEmpty,
    parseIntOr,
    parseBoolOr,
    parseStringOr
} from '../../App/AppxUtils';

import './index.css';


class InputEmail extends React.Component {
    constructor(props) {
        super(props);

        this.name = parseStringOr(this.props.name, "email");

        if (this.name === "") {
            throw new Error('Name is required!');
        }

        this.label = parseStringOr(props.label, null);
        this.placeholder = parseStringOr(props.placeholder, null);
        this.disabled = parseBoolOr(props.disabled, false);
        this.required = parseBoolOr(props.required, true);
        this.len = parseIntOr(this.props.len, 255);
        this.setRules();

        this.state = {
            v: ""
        }

        this.onChange = this.onChange.bind(this);
        this.setVal = this.setVal.bind(this);
    }

    setRules() {
        const { t } = this.props;

        this.rules = [{
            required: this.required,
            type: 'email',
            message: t((this.label != null) ? `Please input your ${this.label}!` : `Please input your ${this.name}!`)
        }];

        if (isArrayNotEmpty(this.props.rules)) {
            for (let i in this.props.rules) {
                let row = this.props.rules[i];

                for (let field in row) {
                    this.rules[i][field] = row[field];
                }
            }
        }
    }

    onChange(e) {
        this.setVal(e.target.value)
    }

    setVal(v) {
        if (undefined !== this.props.onChange) {
            this.props.onChange(this.name, v);
        }

        this.setState({v: v});
    }

    render() {
        return (
            <Form.Item
                name={this.name}
                label={this.label}
                rules={this.rules}
                style={this.props.style}>
                <Input
                    type="text"
                    placeholder={this.placeholder}
                    addonBefore={this.props.addonBefore}
                    addonAfter={this.props.addonAfter}
                    len={this.len}
                    disabled={this.disabled}
                    onChange={this.onChange}
                    onPressEnter={e => e.preventDefault()} />
            </Form.Item>
        )
    }
}

export default withTranslation("appxweb")(InputEmail);