import React from 'react'
import { AppxApi } from '../components/App/AppxApi';
import { parseStringOr, parseJsonOr } from "../components/App/AppxUtils";

const AuthContext = React.createContext();
const AuthConsumer = AuthContext.Consumer;

class AuthProvider extends React.Component {
    api = null;

    constructor(props) {
        super(props);

        this.app = parseStringOr(process.env.REACT_APP_CFG_APP, "");

        if (this.app === "") {
            throw new Error('App is mandatory!');
        }

        this.org = parseStringOr(process.env.REACT_APP_CFG_ORG, "");

        if (this.org === "") {
            throw new Error('Org is mandatory!');
        }

        this.api = new AppxApi();
    }

    render() {
        const value = {
            getModelList: this.getModelList.bind(this),
            getModelData: this.getModelData.bind(this),
            setModelData: this.setModelData.bind(this),
            createModelData: this.createModelData.bind(this),
            doModelAction: this.doModelAction.bind(this)
        };

        return (<AuthContext.Provider value={value} {...this.props} />)
    }

    overrideApiParameters(p) {
        p.org = this.org;
        p.app = this.app;
        return p;
    }

    async getModelList(p) {
        //override the org and app based on currnet state
        p = this.overrideApiParameters(p);
        return await this.api.getModelList(p);
    }

    async getModelData(p) {
        //override the org and app based on currnet state
        p = this.overrideApiParameters(p);
        return await this.api.getModelData(p);
    }

    async setModelData(p) {
        //override the org and app based on currnet state
        p = this.overrideApiParameters(p);
        return await this.api.setModelData(p);
    }

    async createModelData(p) {
        //override the org and app based on currnet state
        p = this.overrideApiParameters(p);
        return await this.api.createModelData(p);
    }
    async doModelAction(p) {
        //override the org and app based on currnet state
        p = this.overrideApiParameters(p);
        return await this.api.doModelAction(p);
    }
}

export {
    AuthContext,
    AuthProvider,
    AuthConsumer,
}