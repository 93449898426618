import React from 'react';
import { HashRouter, Switch, Route} from 'react-router-dom';
import modules from '../../modules';

import './App.css';

class App extends React.Component {
    render() {
        const routes = modules.map(module => {
            const Module = module.routeProps.component;

            return (
                <Route key={module.key} exact path={module.routeProps.path}>
                    <Module />
                </Route>
            );
        });

        return (
                <HashRouter>
                    <Switch>{routes}</Switch>
                </HashRouter>
        );
    }
}

export default App;
