import React from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Checkbox } from 'antd';

import {
    parseStringOr,
    parseBoolOr,
    isArrayNotEmpty
} from '../../App/AppxUtils';

import './index.css';


class InputBoolean extends React.Component {
    timmer = null;

    constructor(props) {
        super(props);

        this.name = parseStringOr(props.name, "");

        if (this.name === "") {
            throw new Error('Name is required!');
        }

        this.formRef = props.formRef || null;

        if (this.formRef == null) {
            throw new Error('Form reference is required!');
        }

        this.label = parseStringOr(props.label, null);
        this.disabled = parseBoolOr(props.disabled, false);
        this.required = parseBoolOr(props.required, false);
        this.defaultChecked = parseBoolOr(props.defaultChecked, false);
        this.valuePropName = parseStringOr(props.valuePropName, '1');
        this.setRules();


        this.state = {
            checked: this.defaultChecked
        }

        this.onChange = this.onChange.bind(this);
        this.setVal = this.setVal.bind(this);
    }

    setRules() {
        const { t } = this.props;

        this.rules = [{
            required: this.required,
            message: t((this.label != null) ? `Please choose a ${this.label}!` : `Please choose a ${this.name}!`)
        }];

        if (isArrayNotEmpty(this.props.rules)) {
            for (let i in this.props.rules) {
                let row = this.props.rules[i];

                for (let field in row) {
                    this.rules[i][field] = row[field];
                }
            }
        }
    }

    async componentDidUpdate(prevProps) {
        let label = parseStringOr(this.props.label, null);

        if (label !== prevProps.label) {
            this.label = label;
        }

        this.timmer = setTimeout(() => {
            if (this.formRef.current != null) {
                let value = this.formRef.current.getFieldValue(this.name);
                value = parseBoolOr(value, this.state.checked);

                if (this.state.checked !== value) {
                    this.setVal(value);
                }
            }
        }, 200);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    onChange(e) {
        this.setVal(e.target.checked);
    }

    setVal(v) {
        if (undefined !== this.props.onChange) {
            this.props.onChange(this.name, v);
        }

        this.setState({checked: v});

        if (this.formRef != null) {
            this.formRef.current.setFieldsValue({[this.name]: v ? '1' : '0'});
        }
    }

    render() {
        return (
            <Form.Item name={this.name} valuePropName={this.valuePropName} style={this.props.style}>
                <Checkbox defaultChecked={this.defaultChecked} checked={this.state.checked}
                    disabled={this.disabled} onChange={this.onChange}>{this.label}</Checkbox>
            </Form.Item>
        )
    }
}

export default withTranslation("appxweb")(InputBoolean);