function isObjectNotEmpty(obj) {
    return (obj != null && typeof(obj) === 'object' && Object.keys(obj).length !== 0);
}

function isStringEmpty(str) {
    str = (undefined === str || str == null) ? "" : String(str).trim();
    return (str === "");
}

function isValidEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function isArrayNotEmpty(array) {
    return (Array.isArray(array) && array.length > 0);
}

function isEqualArray(array1, array2, strict) {
    if (!Array.isArray(array1) || !Array.isArray(array2)) {
        return false;
    }
    else if (array1.length !== array2.length) {
        return false;
    }
    else {
        strict = parseBoolOr(strict, false);

        if (strict) {
            for (let i = 0; i < array1.length; i ++) {
                if (array1[i] !== array2[i]) {
                    return false;
                }
            }
        }
        else {
            for (let i = 0; i < array1.length; i ++) {
                if (array1[i] != array2[i]) {
                    return false;
                }
            }
        }
    }

    return true;
}

function parseStringOr(str, def) {
    if (undefined === def) {
        throw new Error('Function parseStringOr: Default value is required!');
    }

    str = (undefined === str || str == null) ? "" : String(str).trim();

    return (str === "") ? def : str;
}

function parseIntOr(num, def) {
    if (undefined === def) {
        throw new Error('Function parseIntOr: Default value is required!');
    }

    num = parseInt(num);
    return isNaN(num) ? def : num;
}
function parseNumberOr(num, def) {
    if (undefined === def) {
        throw new Error('Function parseIntOr: Default value is required!');
    }

    num = parseFloat(num);
    return isNaN(num) ? def : num;
}
/**
 * Formats acepting as boolean:
 *     - boolean
 *     - String(insensitive case)
 *     - Interger 1 and 0
 */
function parseBoolOr(bool, def) {
    if (undefined === def) {
        throw new Error('Function parseBoolOr: Default value is required!');
    }
    bool = String(bool).trim().toLowerCase();

    switch (bool) {
        case '1':
        case 'true':
            bool = true;
            break;

        case '0':
        case 'false':
            bool = false;
            break;

        default:
            bool = def;
            break;
    }

    return bool;
}


function parseFloatOr(num, def) {
    if (undefined === def) {
        throw new Error('Function parseFloatOr: Default value is required!');
    }

    num = parseFloat(num);
    return isNaN(num) ? def : num;
}

function parseJsonOr(json, def) {
    if (undefined === def) {
        throw new Error('Function parseJsonOr: Default value is required!');
    }

    try {
        json = JSON.parse(json);
    }
    catch(e) {
        console.log('Function parseJsonOr: Error parsing JSON:', json);
        json = def;
    }

    return json;
}

function objToQueryString(obj) {
    const keyValuePairs = [];

    for (const key in obj) {
        keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
    }

    return keyValuePairs.join('&');
}

function parseGoogleAddress(geo) {
    let address = {
        suite: "",
        civic: "",
        street: "",
        streetShort: "",
        city: "",
        state: "",
        stateShort: "",
        country: "",
        countryShort: "",
        zip: "",
        lat: "",
        lng: "",
        formattedAddress: ""
    };

    if (undefined !== geo.formatted_address) {
        address['formattedAddress'] = geo.formatted_address;
    }

    if (undefined !== geo.address_components) {
        for (let i = 0; i < geo.address_components.length; i ++) {
            let comp = geo.address_components[i];

            if (comp.types.indexOf("subpremise") !== -1) {
                address['suite'] = comp.long_name;
            }

            if (comp.types.indexOf("street_number") !== -1) {
                address['civic'] = comp.long_name;
            }

            if (comp.types.indexOf("route") !== -1) {
                address['street'] = comp.long_name;
                address['streetShort'] = comp.short_name;
            }

            if (comp.types.indexOf("locality") !== -1) {
                address['city'] = comp.long_name;
            }

            if (comp.types.indexOf("administrative_area_level_1") !== -1) {
                address['state'] = comp.long_name;
                address['stateShort'] = comp.short_name;
            }

            if (comp.types.indexOf("country") !== -1) {
                address['country'] = comp.long_name;
                address['countryShort'] = comp.short_name;
            }

            if (comp.types.indexOf("postal_code") !== -1) {
                address['zip'] = comp.long_name;
            }
        }
    }

    if (undefined !== geo.geometry &&
        undefined !== geo.geometry.location) {
        address['lat'] = geo.geometry.location.lat();
        address['lng'] = geo.geometry.location.lng();
    }

    return address;
}


function truncateText(str, len, ending) {
    str = parseStringOr(str, "");

    if (str === "") {
        return str;
    }

    len = parseIntOr(len, 20);

    if (str.length <= len) {
        return str;
    }

    ending = parseStringOr(ending, '');
    str = str.substring(0, len - ending.length);

    return str + ending;
}
/**
 * If path is null it will take the current path.
 * Trim: clean segments from the path. Example:
 *     url: /slug/slug2/slug3   with trim = 0, null or undefined
 *     url: /slug/slug2         with trim = 1
 *     url: /slug/              with trim = 2
 *
 * To work with current path and trim
 *     getPathName(null, 1)
 *
 * RouterBrowser
 *
function getPathName(path, trim) {
    if (path == null) {
        path = window.location.pathname;
    }

    trim = parseIntOr(trim, 0);
    path = String(path).replace(/\/$/, "");

    if (trim == 0) {
        return path;
    }

    path = path.split('/');

    if (path.length <= trim) {
        return "";
    };

    trim = -Math.abs(trim);
    path = path.slice(0, trim).join('/');

    return path.replace(/\/$/, "");
}
/**
 * If path is null it will take the current path.
 * Trim: clean segments from the path. Example:
 *     url: /slug/slug2/slug3   with trim = 0, null or undefined
 *     url: /slug/slug2         with trim = 1
 *     url: /slug/              with trim = 2
 *
 * To work with current path and trim
 *     getPathName(null, 1)
 *
 * RouterHash
 */
function getPathName(path, trim) {
    if (path == null) {
        let aux = window.location.href.split('#');
        path = (aux.length > 1) ? aux[1] : '/';
    }

    trim = parseIntOr(trim, 0);
    path = String(path).replace(/\/$/, "");

    if (trim === 0) {
        return path;
    }

    path = path.split('/');

    if (path.length <= trim) {
        return "";
    };

    trim = -Math.abs(trim);
    path = path.slice(0, trim).join('/');

    return path.replace(/\/$/, "");
}
/**
 * Extract object from another object using prefix
 *
 * example: {
 *     step: 0,
 *     card: 0,
 *     frm_customerType: "",
 *     frm_planId: 0,
 *     frm_serviceId: 0,
 * }
 *
 * prefix "frm_"
 * result {customerType: "", planId: 0, serviceId: 0}
 */
function extractObj(object, prefix) {
    let obj = {};

    if (isObjectNotEmpty(object)) {
        prefix = String(prefix).trim();

        if (prefix !== "") {
            for (let key in object) {
                let p = key.substr(0, prefix.length);
                if (p === prefix) {
                    obj[key.substr(prefix.length)] = object[key];
                }
            }
        }
    }

    return obj;
}

// takes props that start with form_
function extractForm(object) {
    let obj = {};

    if (isObjectNotEmpty(object)) {
        for (let key in object) {
            let p = key.substr(0, 5);

            if (p === 'form_') {
                obj[key.substr(5)] = object[key];
            }
        }
    }

    return obj;
}

function gsFilter(val, searchIn) {
    let where = '';
    val = val.trim().split(' ');

    if (val.length > 0) {
        let ww = [];

        for (let i in val) {
            let wl = [];

            for (let s in searchIn) {
                wl.push(`${searchIn[s]} LIKE '%${val[i]}%'`);
            }
            ww.push(`(${wl.join(' OR ')})`);
        }

        where = ww.join(' AND ');
    }

    return where;
}

export {
    isObjectNotEmpty,
    isStringEmpty,
    isValidEmail,
    isArrayNotEmpty,
    isEqualArray,
    parseIntOr,
    parseStringOr,
    parseFloatOr,
    parseBoolOr,
    parseJsonOr,
    parseNumberOr,
    objToQueryString,
    parseGoogleAddress,
    getPathName,
    truncateText,
    extractObj,
    extractForm,
    gsFilter
}