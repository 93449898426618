import React from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Input } from 'antd';
import {
    parseIntOr,
    parseBoolOr,
    parseStringOr,
    isObjectNotEmpty,
    isArrayNotEmpty,
    parseGoogleAddress
} from '../../../components/App/AppxUtils';
import GoogleApi from '../../App/GoogleApi';

import './index.css';

class InputGEO extends React.Component {
    autocomplete = null;

    constructor(props) {
        super(props);

        this.name = parseStringOr(this.props.name, "");

        if (this.name === "") {
            throw new Error('Name is required!');
        }

        this.formRef = props.formRef || null;

        if (this.formRef == null) {
            throw new Error('Form reference is required!');
        }

        this.label = parseStringOr(this.props.label, null);
        this.required = parseBoolOr(this.props.required, true);
        this.len = parseIntOr(this.props.len, 255);
        this.setRules();

        this.state = {
            v: ""
        }

        this.onChange = this.onChange.bind(this);
        this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    }

    setRules() {
        const { t } = this.props;

        this.rules = [{
            required: this.required,
            message: t((this.label != null) ? `Please input your ${this.label}!` : `Please input your ${this.name}!`)
        }];

        if (isArrayNotEmpty(this.props.rules)) {
            for (let i in this.props.rules) {
                let row = this.props.rules[i];

                for (let field in row) {
                    this.rules[i][field] = row[field];
                }
            }
        }
    }

    componentDidMount() {
        const { google } = this.props;
        this.autocomplete = new google.maps.places.Autocomplete(document.getElementById(this.name), {});
        this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
    }

    async handlePlaceSelect(e) {
        let address = await this.autocomplete.getPlace();
        address = await parseGoogleAddress(address);

        if (address.lat != null && address.lng != null) {
            if (undefined !== this.props.onChange) {
                this.props.onChange(this.name, address);
            }

            if (undefined !== address.formattedAddress) {
                this.setVal(address.formattedAddress);
            }
        }
    }

    onChange(value) {
        this.setVal(value);
    }

    setVal(v) {
        this.setState({v: v});

        if (this.formRef != null) {
            this.formRef.current.setFieldsValue({[this.name]: v});
        }
    }

    render() {
        return (
            <Form.Item name={this.name} label={this.label} rules={this.rules} style={this.props.style}>
                <Input type="text" len={this.len} ref="input" onPressEnter={e => e.preventDefault()} />
            </Form.Item>
        )
    }
}

export default GoogleApi(withTranslation("appxweb")(InputGEO));