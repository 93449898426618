import React from 'react';
import { withTranslation } from 'react-i18next';


class Loading extends React.Component {
    render() {
        const { t } = this.props;
        return (<div className="loading">{t('Loading')}...</div>);
    }
}

export default withTranslation("appxweb")(Loading);