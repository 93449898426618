import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'antd';

import './index.css';

class SaveButton extends React.Component {

    render() {
        const { t } = this.props;

        return (
            <Button
                className={this.props.className}
                type={this.props.type || 'primary'}
                htmlType="submit"
                style={this.props.style}>{this.props.title || t('Save')}</Button>
        )
    }
}

export default withTranslation("appxweb")(SaveButton);