import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import cxaen from './en/cxa.json';
import cxafr from './fr/cxa.json';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: false
        },
        debug: false,
        fallbackLng: 'en',
        lng: 'en',
        whitelist: ['en', 'fr'],
        resources: {
            en: {
                cxa: cxaen
            },
            fr: {
                cxa: cxafr
            }
        },
        backend: {
            loadPath: './{{lng}}/{{ns}}.json',
        },
    });

export default i18n;