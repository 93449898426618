import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Form, Row, Col, Button, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight, faCheckCircle } from '@fortawesome/pro-solid-svg-icons';

import { AuthContext } from '../../contexts/AuthContext';
import { PageView, PageViewSection } from '../../components/PageView';
import Price from '../../components/Price';
import Loading from '../../components/Loading';
import WelcomeView from "./view";
import {
    InputText,
    InputInteger,
    InputEmail,
    InputBoolean,
    InputRadio,
    InputGEO
} from '../../components/ModelFormView';

import {
    parseIntOr,
    parseBoolOr,
    parseStringOr,
    parseFloatOr,
    isObjectNotEmpty
} from '../../components/App/AppxUtils';

import './index.css';
import fam from "./familytv.jpg";


function sumParts(parts) {
    let total = 0;

    for (let key in parts) {
        total += parts[key];
    }

    return total;
}

function r2Dec(num) {
    num = parseFloatOr(num, 0);
    return Math.round(num * 100) / 100;
}

function w2Dec(num) {
    return r2Dec(num).toFixed(2);
}

class Welcome extends React.Component {
    static contextType = AuthContext;

    formRef = React.createRef();
    plan = {}
    planApiCode = "";
    tvPlan = {};
    tvPlanId = 0;
    defPrices = {
        unlimited_can_usa_long_distance: 6.75,
        digital_recorder: 3.00,
        decoders: {
            'LOCATION': 5.00,
            'ACHAT': 129.95,
        }
    };

    constructor(props) {
        super(props);

        const params = new URLSearchParams(props.location.search);
        this.planApiCode = parseStringOr(params.get('planApiCode'), "");
        this.tvPlanId = parseIntOr(params.get('tvPlanId'), 0);

        this.state = {
            loading: true,
            validUrl: (this.planApiCode !== ""),
            invoice: {
                plan: 0,
                tvplan: 0,
                install: 0,
                tvPlan: 0,
                unlimited_can_usa_long_distance: 0,
                digital_recorder: 0,
                decoders: 0
            },
            overview: 0,
            overviewToShow: 0,
            decoder: "LOCATION",
            numOfDecoders: 0,
            decodersToShow: 0,
        }

        this.onFinish = this.onFinish.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    async componentDidMount() {
        let { validUrl, invoice, decoder, numOfDecoders } = this.state;

        if (validUrl) {
            let mdl = await this.context.doModelAction({
                model: 'plan',
                action: 'apicode',
                data: {
                    apiCode: this.planApiCode
                }
            });

            if (mdl != null && isObjectNotEmpty(mdl.data)) {
                this.plan = mdl.data;
                invoice['plan'] = r2Dec(mdl.data.monthlyPrice);

                if (!this.plan.freeSetupFee) {
                    invoice['install'] = r2Dec(mdl.data.planSetupFee);
                }
            }
            else {
                validUrl = false;
            }

            if (this.tvPlanId > 0) {
                mdl = await this.context.getModelData({model: 'tvplan', id: this.tvPlanId});

                if (mdl != null && isObjectNotEmpty(mdl.rec)) {
                    this.tvPlan = mdl.rec;
                    invoice['tvplan'] = r2Dec(mdl.rec.planPrice);
                }
            }
            // Decoders
            invoice['decoders'] = r2Dec(this.defPrices['decoders'][decoder] * numOfDecoders);
            let overview = sumParts(invoice);

            this.setState({
                overview: r2Dec(overview),
                overviewToShow: w2Dec(overview),
                invoice: invoice,
                decodersToShow: w2Dec(invoice['decoders'])
            });
        }

        this.setState({loading: false});
    }


    async onChange(name, value) {
        let { invoice } = this.state;

        switch (name) {
            case 'digital_recorder':
            case 'unlimited_can_usa_long_distance':
                value = parseBoolOr(value, false);
                invoice[name] = (value) ? this.defPrices[name] : 0;
                break;

            case 'decoder':
            case 'numOfDecoders':
                await this.setState({[name]: value});
                let { decoder, numOfDecoders } = this.state;
                invoice['decoders'] = r2Dec(this.defPrices['decoders'][decoder] * numOfDecoders);
                break;

            case 'credit_card':
                value = parseBoolOr(value, false);

                if (value) {
                    this.formRef.current.setFieldsValue({bank_transfer: false});
                }
                break;

            case 'bank_transfer':
                value = parseBoolOr(value, false);

                if (value) {
                    this.formRef.current.setFieldsValue({credit_card: false});
                }
                break;
        }

        let overview = sumParts(invoice);

        this.setState({
            overview: r2Dec(overview),
            overviewToShow: w2Dec(overview),
            invoice: invoice,
            decodersToShow: w2Dec(invoice['decoders'])
        });
    }

    async onFinish(values) {
        let credit_card = parseBoolOr(values.credit_card, false);
        let bank_transfer = parseBoolOr(values.bank_transfer, false);

        if (!credit_card && !bank_transfer) {
            Modal.error({
                title: this.props.t('SignUpRequest.required_payment_title'),
                content: this.props.t('SignUpRequest.required_payment_content'),
            });
            return false;
        }
        else {
            values.planId = this.plan.id;
            values.planApiCode = this.planApiCode;
            values.tvPlanId = this.tvPlanId;
            values.overview = this.state.overview;
            values.overviewBasedOn = {
                defaultPricies: this.defPrices,
                selections: this.state.invoice
            };

            let response = await this.context.createModelData({
                model: 'signuprequest',
                data: {
                    apiEmailStep1: 1,
                    des: "Inserted from website form",
                    jdata: JSON.stringify(values),
                    planId: this.plan.id,
                    tvPlanId: this.tvPlanId,
                }
            });

            let signUpRequestId = (response != null) ? parseIntOr(response.id, 0) : 0;

            if (signUpRequestId  > 0) {
                this.props.history.push(`view/${signUpRequestId}`);
            }
        }
    }

    render() {
        const { t, i18n } = this.props;
        const gutter = 10;
        const colSize = {
            xs: 17,
            sm: 19,
            md: 20,
        };

        const colSizePrice = {
            xs: 7,
            sm: 5,
            md: 4,
        };

        const decoders = [
            {
                value: "LOCATION",
                label: t('SignUpRequest.location'),
            },
            {
                value: "ACHAT",
                label: t('SignUpRequest.achat'),
            }
        ];

        let planTvDesField = 'des';
        let planLabelField = 'label';

        if (i18n.language === 'fr') {
            planTvDesField = 'desFr';
            planLabelField = 'labelFr';
        }
        let familyNode = (<img className="img-fam" src={fam} />);
        let changeLang = (l) =>{
            this.setState({lang:l})
        }
        return (
            <PageView                
                title={t('index.title')}
                subTitle={t('index.subTitle')}
                img={familyNode}
                onChangeLang={changeLang}
                >
               
                <WelcomeView />
               
            </PageView>
        );
    }
}

export default {
    routeProps: {
        path: '/',
        component: withRouter(withTranslation('cxa')(Welcome))
    },
    key: 'module-signuprequest',
}