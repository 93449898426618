import { parseStringOr } from './AppxUtils';

class ModelClass {
    constructor(name) {
        this.name = name;
        this.rec = {};
        this.uri = "";
    }

    preload(data) {
        for ( let k in data ) {
            let v = data[k];
            this.rec[k] = v;
        }
        this.id = data.id||0;
    }

    getUrl() {
        return `/api/${this.name}/${this.id}`;
    }
    getKey() {
        return `${this.name}.${this.id}`;
    }

    get(k) {
        return this.rec[k] || "";
    }

    set(k, v) {
        this.rec[k] = v;
    }

    getUri() {
        return this.uri;
    }

    setUri(v) {
        this.uri = v;
    }
}

class ModelList {
    constructor(name){
        this.name = name;
    }

    loadFromArr(tbl) {
        this.list = [];
       try {

        for ( var i=0; i < tbl.length;i++ ) {
            let row = tbl[i];
            //console.log(row);
            let mdl = new ModelClass(this.name);
            mdl.preload(row);
            this.list.push(mdl);
        }

        } catch(err) {
            console.error("Model.Collection:" + err);
        }
    }

    map(cb) {
        if ( this.list == null ) { return;}
        return this.list.map(cb);
    }
}


 class AppxApi {
    constructor() {
        this.url = parseStringOr(process.env.REACT_APP_API_URL, "http://localhost:2600/api/");
        this.apikey = parseStringOr(process.env.REACT_APP_API_KEY, "");

        if (this.apikey === "") {
            throw new Error('ApiKey is mandatory!');
        }
    }

    async getModelList(p) {
        //p should contain sessionToken,org,app,data{} objects
        let model = p.model;
        let col = new ModelList(model);
        let body = {...p};
        body.apikey = this.apikey;
        body.action = "list";

        try {
            let response = await fetch(`${this.url}${model}/list`, {
                method: 'POST',
                crossDomain: true,
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'pragma':'no-cache', 'cache-control': 'no-cache', 'cache-control':'no-store'

                },
                body: JSON.stringify(body)
            });
            let res = await response.json();
            col.loadFromArr(res.list);
            return col;
        }
        catch (error) {
            console.log(error);
            return col;
        }
    }

    async getModelData(p) {
        let model = p.model;
        let body = {...p};
        body.apikey = this.apikey;
        body.action = "get";
        let id = p.id || 0;
        let col = new ModelClass(model);
        id = parseInt(id) || 0;
        if (id == 0) {
            return col;
        }

        try {
            let response = await fetch(`${this.url}${model}/${id}`, {
                method: 'POST',
                crossDomain: true,
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'pragma':'no-cache', 'cache-control': 'no-cache', 'cache-control':'no-store'

                },
                body: JSON.stringify(body)
            });
            let res = await response.json();
            col.preload(res.data);
            return col;
        }
        catch (error) {
            console.log(error);
            return col;
        }
    }

    //note: p should contain a p.data object to be passed to the function in appxserver model
    async doModelAction(p) {
        let model = p.model;
        let action = p.action;
        let body = {...p};
        body.apikey = this.apikey;
        let id = parseInt(p.id);
        let col = new ModelClass(model);
        let url = this.url + model;

        if (!isNaN(id) && id > 0) {
            url += '/' + id;
        }

        url += '/' + action;

        try {
            let response = await fetch(url, {
                method: 'POST',
                crossDomain: true,
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'pragma':'no-cache', 'cache-control': 'no-cache', 'cache-control':'no-store'

                },
                body: JSON.stringify(body)
            });

            let res = await response.json();

            if ( res == null ) {
                return col;
            }

            //maybe we should return res.data
            return res;
        }
        catch (error) {
            console.log(error);
            return col;
        }
    }

    async createModelData(p) {
        let model = p.model;
        let body = {...p};
        body.apikey = this.apikey;
        body.action = "set";
        let col = new ModelClass(model);


        try {
            let response = await fetch(`${this.url}${model}/create`, {
                method: 'POST',
                crossDomain: true,
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'pragma':'no-cache', 'cache-control': 'no-cache', 'cache-control':'no-store'

                },
                body: JSON.stringify(body)
            });
            let res = await response.json();
            if ( res == null ) {
                return col;
            }
            col.preload(res.data);
            return col;
        }
        catch (error) {
            console.log(error);
            return col;
        }
    }
}


export {AppxApi, ModelClass, ModelList};