import React from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Input, Button } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

import {
    isArrayNotEmpty,
    parseIntOr,
    parseBoolOr ,
    parseStringOr
} from '../../App/AppxUtils';

import './index.css';


class InputInteger extends React.Component {

    constructor(props) {
        super(props);

        this.name = parseStringOr(props.name, "");

        if (this.name === "") {
            throw new Error('Name is required!');
        }

        this.buttons = parseBoolOr(props.buttons, true);
        this.size = parseStringOr(props.size, "middle");
        this.min = parseIntOr(props.min, -2147483648); // INT signed
        this.max = parseIntOr(props.max, 2147483647); // INT signed
        this.placeholder = parseStringOr(props.placeholder, null);
        this.disabled = parseBoolOr(props.disabled, false);
        this.required = parseBoolOr(props.required, true);

        if (this.disabled) {
            this.required = false;
        }

        this.formRef = props.formRef || null;

        if (this.buttons && this.formRef == null) {
            throw new Error('Form reference is required if you want to use buttons!');
        }

        this.setRules();

        this.state = {
            v:  parseIntOr(props.initialValue, null)
        }

        this.onChange = this.onChange.bind(this);
        this.onPlus = this.onPlus.bind(this);
        this.onMinus = this.onMinus.bind(this);
    }

    setRules() {
        const { t } = this.props;
        let label = this.name;

        if (this.label != null) {
            label = this.label;
        }
        else if (this.placeholder != null) {
            label = this.placeholder;
        }

        this.rules = [{
            required: this.required,
            message: t(`Please input your ${label}!`)
        }];

        if (isArrayNotEmpty(this.props.rules)) {
            for (let i in this.props.rules) {
                let row = this.props.rules[i];

                for (let field in row) {
                    this.rules[i][field] = row[field];
                }
            }
        }
    }

    onMinus() {
        let v = parseIntOr(this.state.v, 0) - 1;
        if (v < this.min) { v = this.min; }
        this.setVal(v);
    }

    onPlus() {
        let v = parseIntOr(this.state.v, 0) + 1;
        if (v > this.max) { v = this.max; }
        this.setVal(v);
    }

    onChange(e) {
        let v = parseIntOr(e.target.value, null);

        if (v != null) {
            if (v < this.min) { v = this.min; }
            if (v > this.max) { v = this.max; }
        }

        this.setVal(v);
    }

    setVal(v) {
        if (undefined !== this.props.onChange) {
            this.props.onChange(this.name, v);
        }

        this.setState({v: v});

        if (this.formRef != null) {
            this.formRef.current.setFieldsValue({[this.name]: v});
        }
    }

    render() {
        let className = null;
        let addonBefore = null;
        let addonAfter = null;

        if (this.buttons) {
            className = 'custom-input-integer';
            addonBefore = (<Button size={this.size} onClick={this.onMinus}><MinusOutlined /></Button>);
            addonAfter = (<Button size={this.size} onClick={this.onPlus}><PlusOutlined /></Button>);
        }

        return (
            <Form.Item
                name={this.name}
                label={this.props.label}
                rules={this.rules}
                style={this.props.style}
                className={className}>
                <Input
                    type="text"
                    value={this.state.value}
                    size={this.size}
                    addonBefore={addonBefore}
                    addonAfter={addonAfter}
                    onInput={this.onChange}
                    onChange={this.onChange}
                    onPressEnter={e => e.preventDefault()} />
            </Form.Item>
        )
    }
}

export default withTranslation("appxweb")(InputInteger);