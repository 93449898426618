import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Layout } from 'antd';

import AppxLangAvatar from '../AppxLangAvatar';
import HamburgerMenu from '../HamburgerMenu';
import { parseStringOr } from '../App/AppxUtils';

import './index.css';

const { Content, Footer, Sider } = Layout;


class LogoBarCom extends React.Component {
    render() {
        const { t } = this.props;

        return (
            <div className="logos-bar">
                <div className="logos-bar-item">
                    <img alt="MapIcon" src={`${process.env.PUBLIC_URL}/images/map.png`} />
                    <div className="logos-bar-item-text">
                        <span className="logos-bar-item-text-2nd">{t('Header.address_2nd')}</span>
                        <span className="logos-bar-item-text-1st">{t('Header.address_1st')}</span>
                    </div>
                </div>
                <div className="logos-bar-item">
                    <img alt="userIcon" src={`${process.env.PUBLIC_URL}/images/user.png`} />
                    <div className="logos-bar-item-text">
                        <span className="logos-bar-item-text-2nd">{t('Header.timetable_1_2nd')}</span>
                        <span className="logos-bar-item-text-1st">{t('Header.timetable_1_1st')}</span>
                    </div>
                </div>
                <div className="logos-bar-item">
                    <div className="logos-bar-item-text">
                        <span className="logos-bar-item-text-2nd">{t('Header.timetable_2_2nd')}</span>
                        <span className="logos-bar-item-text-1st">{t('Header.timetable_2_1st')}</span>
                    </div>
                </div>
                <div className="logos-bar-item">
                    <img alt="sunIcon" src={`${process.env.PUBLIC_URL}/images/sun.png`} />
                    <div className="logos-bar-item-text">
                        <span className="logos-bar-item-text-2nd">{t('Header.timetable_1')}</span>
                        <span className="logos-bar-item-text-2nd">{t('Header.timetable_2')}</span>
                    </div>
                </div>
                <div className="logos-bar-item">
                    <AppxLangAvatar onChange={this.props.onChangeLang}/>
                </div>
            </div>
        );
    }
}

const LogoBar = withRouter(withTranslation("cxa")(LogoBarCom));

class PageViewCom extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            collapsed: true
        }

        this.handleHamburgerClick = this.handleHamburgerClick.bind(this);
        this.onBreakpoint = this.onBreakpoint.bind(this);
    }

    handleHamburgerClick() {
        this.setState({collapsed: !this.state.collapsed});
    }

    onBreakpoint(broken) {
        if (!broken) {
            this.setState({collapsed: true});
        }
    }

    render() {
        return (
            <Layout>
                <Sider
                    className="upi-sider"
                    trigger={null}
                    collapsible
                    collapsed={this.state.collapsed}
                    breakpoint="lg"
                    onBreakpoint={this.onBreakpoint}
                    width={320}
                    collapsedWidth="0">
                    <div className="upi-sider-logobox">
                        <img className="logo" alt="logo"
                            src={`${process.env.PUBLIC_URL}/cxatop-white.png`} />
                    </div>
                    <LogoBar onChangeLang={this.props.onChangeLang}/>
                </Sider>
                <Layout className="layout">
                    <header className="upi-header">
                        <div className="upi-header-content">
                            <div className="hamburger-menu-bar">
                                <HamburgerMenu
                                    menuClicked={this.handleHamburgerClick.bind(this)}
                                    className="hamburger-menu"
                                    isOpen={(this.state.collapsed == false)}
                                    animationDuration={0.1}
                                    />
                            </div>
                            {this.state.collapsed && (
                            <img className="logo" alt="logo"
                                src={`${process.env.PUBLIC_URL}/cxatop-white.png`} />
                            )}
                            <LogoBar onChangeLang={this.props.onChangeLang}/>
                        </div>
                    </header>
                    <Content>
                        {this.props.img}
                        <div className="site-page-content">
                            
                            {this.props.title && (<h1 className="pv-title">{this.props.title}</h1>)}
                            {this.props.subTitle && (<p className="pv-subtitle">{this.props.subTitle}</p>)}
                            {this.props.children}
                        </div>
                    </Content>
                    <Footer className="upi-footer">
                        <img alt="footer" src={`${process.env.PUBLIC_URL}/images/footer.png`} />
                    </Footer>
                </Layout>
            </Layout>
        )
    }
}

const PageView = withRouter(withTranslation("cxa")(PageViewCom));

class PageViewSectionCom extends React.Component {
    render() {
        let className = "pv-section";
        let classNameProps = parseStringOr(this.props.className, "");

        if (classNameProps !== "") {
            className += ' ' + classNameProps;
        }

        return (
            <div className={className}>
                {this.props.section && (<h3>{this.props.section}</h3>)}
                {this.props.title && (<h2>{this.props.title}</h2>)}
                <div className="pv-section-content" style={this.props.style}>{this.props.children}</div>
            </div>
        )
    }
}

const PageViewSection = withRouter(withTranslation("cxa")(PageViewSectionCom));

export {
    PageView,
    PageViewSection,
};
